
const applicationEnvironment = "qa"; /* this value will be replaced by pipeline execution, 
  by the following command: sed -i "s/let applicationEnvironment = \"[^\"]*\"/let applicationEnvironment = \"$(APPLICATION_ENVIRONMENT)\"/" "$file_path"
  do not remove it */

const version = "20240830.2"; /* this value will be replaced by pipeline execution, 
  by the following command: sed -i "s/const version = \"[^\"]*\"/const version = \"$new_version\"/" "$file_path"
  do not remove it */

let CLIENT_ID = "";

let IAL_API_URL = "";

let IAL_FRONT_URL = "";

/* DATADOG VARIABLES */
const applicationId = "f16264cd-eb9c-4707-bc10-bb2839b0a902"; /* this value will be replaced by pipeline execution, 
  by the following command: sed -i "s/const applicationId = \"[^\"]*\"/const applicationId = \"$(DD_APPLICATION_ID)\"/" "$file_path"
  do not remove it */

const clientToken = "pub7387a99b965b45a460be9604cd97bcf8"; /* this value will be replaced by pipeline execution, 
  by the following command: sed -i "s/const clientToken = \"[^\"]*\"/const clientToken = \"$(DD_CLIENT_TOKEN)\"/" "$file_path"
  do not remove it */

const service = "interactionlog";
const site = "datadoghq.com";

if (applicationEnvironment === "dev") {
    CLIENT_ID = "11d035d4-224e-4864-8f3e-fd252ebb505d";
    IAL_API_URL = "https://api-interactionlog-dev.ab-inbev.com";
    IAL_FRONT_URL = "https://interactionlog-dev.ab-inbev.com";
};

if (applicationEnvironment === "qa") {
    CLIENT_ID = "11d035d4-224e-4864-8f3e-fd252ebb505d";
    IAL_API_URL = "https://api-interactionlog-qa.ab-inbev.com";
    IAL_FRONT_URL = "https://interactionlog-qa.ab-inbev.com";
};

if (applicationEnvironment === "prod") {
    CLIENT_ID = "0986e4ea-d0e6-4084-a8f2-cc3f89a101cd";
    IAL_API_URL = "https://api-interactionlog.ab-inbev.com";
    IAL_FRONT_URL = "https://interactionlog.ab-inbev.com";
};

export {
    CLIENT_ID,
    IAL_API_URL,
    applicationId,
    clientToken,
    applicationEnvironment,
    version,
    service,
    site,
    IAL_FRONT_URL
};